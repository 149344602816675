import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GALLERY_CONFIG, GalleryConfig, GalleryModule, ImageSize } from 'ng-gallery';
import { LIGHTBOX_CONFIG, LightboxConfig, LightboxModule } from 'ng-gallery/lightbox';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { ImageGalleryComponent } from './components/gallery/gallery.component';
import { ImageDropComponent } from './components/image-drop/image-drop.component';
import { ModalComponent } from './components/modal/modal.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { SafePipe } from './pipes/safe-pipe';
import { CardHeaderComponent } from './card-header/card-header.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';


@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    ModalComponent,
    FileSelectorComponent,
    FileDropDirective,
    ImageDropComponent,
    ImageGalleryComponent,
  ],
  imports: [
    GalleryModule,
    LightboxModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    CardHeaderComponent,
    ScrollingModule
  ],
  providers: [  
    provideFirebaseApp(() => initializeApp(environment.firebase)) as any,
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    {
    provide: GALLERY_CONFIG,
    useValue: {
      imageSize:  ImageSize.Contain,
    } as GalleryConfig
  },
  {
    provide: LIGHTBOX_CONFIG,
    useValue: {
      keyboardShortcuts: false,
      exitAnimationTime: 1000
    } as LightboxConfig
  }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
